<template>
  <div class="custom-actions">
    <button class="btn btn-sm btn-outline-success m-1"
            @click="itemAction('edit-item', rowData, rowIndex)">
      <i class="fas fa-edit"></i> Edit
    </button>

    <button class="btn btn-sm btn-outline-danger m-1"
            @click="itemAction('delete-item', rowData, rowIndex)">
      <i class="far fa-trash-alt"></i> Delete
    </button>
    
    <button class="btn btn-sm btn-outline-primary m-1"
            @click="itemAction('notify-item', rowData, rowIndex)"
            :disabled="rowData.notif_sent === 1"
            v-if="rowData.notif_sent === 0">
      <i class="far fa-bell"></i> Notify
    </button>
    
    <button class="btn btn-sm btn-primary" v-else disabled>
      Notified
    </button>
  </div>
</template>

<script>
  const EditCategory =
    () => import('@/components/admin/category/video/EditCategory.vue');

  export default {
    props : {
      rowData : {
        type     : Object,
        required : true,
      },
      rowIndex : {
        type : Number,
      },
    },
    methods : {

      /**
       * Item actions
       * @param action
       * @param data
       * @param index
       */
      // eslint-disable-next-line no-unused-vars
      itemAction(action, data, index) {
        if (action === 'delete-item') {
          this.$modal.show('dialog', {
            title : 'Please confirm:',
            text  : `Are you sure you want to delete category ` +
              `<b>${data.category_title}</b>?`,
            buttons : [ {
              title   : 'Yes',
              handler : () => {
                this.$events.fire('loading-state', true);
                this.$http.delete('/api/category', {
                  data : {
                    'categoryId' : data.category_id,
                  },
                }).then(() => {
                  this.closeDialog();
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Deleted category successfully.',
                  });
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                }).finally(() => {
                  this.$events.fire('loading-state', false);
                });
              },
            }, {
              title : 'No',
            } ],
          });
        } else if (action === 'edit-item') {
          this.$modal.show(EditCategory, {
            data : data,
          }, {
            height   : 'auto',
            adaptive : true,
          }, {
            'reload-table' : (event) => {
              this.$parent.reload();
            },
          });
        } else if (action === 'notify-item') {
          this.$modal.show('dialog', {
            title : 'Send Notification',
            text  : `Send New Category Notification for ` +
              `<b>${data.category_title}</b>?`,
            buttons : [ {
              title   : 'Yes',
              handler : () => {
                this.$events.fire('loading-state', true);
                this.$http.post('/api/notification/admin/new_category', {
                  'categoryId' : data.category_id,
                }).then(() => {
                  this.closeDialog();
                  this.$notify({
                    group : 'notif',
                    type  : 'success',
                    title : 'Success!',
                    text  : 'Notification sent.',
                  });
                }).catch(() => {
                  this.$notify({
                    group : 'notif',
                    type  : 'error',
                    title : 'Failed',
                    text  : 'Oops! Something went wrong!',
                  });
                }).finally(() => {
                  this.$events.fire('loading-state', false);
                });
              },
            }, {
              title : 'No',
            } ],
          });
        }
      },

      /**
       * Close Dialog
       */
      closeDialog() {
        this.$parent.reload();
        this.$modal.hide('dialog');
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../../assets/scss/components/admin/category/video/category-list-actions";
</style>